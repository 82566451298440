import type {ImageField, PrismicDocument} from '@prismicio/types';
import type {MetaObject} from '@nuxt/schema';
import {useRequestHeaders, linkResolver} from '#imports';

type SeoData = {
    metaDescription?: string;
    metaTitle?: string;
    openGraphTitle?: string;
    openGraphDescription?: string;
    openGraphImage?: ImageField;
    twitterAccount?: string;
    noIndex?: boolean;
};

function getBaseUrl() {
    if (import.meta.server) {
        const headers = useRequestHeaders();
        return `https://${headers.host}`;
    } else {
        return window.location.origin;
    }
}

function getLocaleWithCorrectFormat(locale?: string) {
    return locale?.replace('_', '-') ?? 'en';
}

function makeHeadMetaData(document?: PrismicDocument<SeoData>, locale?: string): MetaObject {
    if (!document) {
        return {
            htmlAttrs: {
                lang: getLocaleWithCorrectFormat(locale),
            },
        };
    }
    return {
        title: document.data.metaTitle,
        htmlAttrs: {
            lang: getLocaleWithCorrectFormat(locale),
        },
        link: [
            {
                rel: 'canonical',
                href: getBaseUrl() + linkResolver(document).replace(/\/$/, ''),
            },
        ],
        meta: [
            {
                hid: 'description',
                name: 'description',
                content: document.data.metaDescription ?? '',
            },
            {
                property: 'og:title',
                content: document.data.openGraphTitle ?? '',
            },
            {
                property: 'og:description',
                content: document.data.openGraphDescription ?? '',
            },
            {
                property: 'og:image',
                content: document.data.openGraphImage?.url ?? '',
            },
            {
                property: 'og:type',
                content: 'website',
            },
            {
                property: 'twitter:creator',
                content: document.data.twitterAccount ?? '',
            },

            document.data.noIndex
                ? {
                      hid: 'robots',
                      name: 'robots',
                      content: 'noindex',
                  }
                : {},
        ],
    };
}

export function useHeadMetadata(document?: PrismicDocument<SeoData>, locale?: string): MetaObject {
    return makeHeadMetaData(document, locale);
}
