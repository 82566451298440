import {useNuxtApp, useRequestHeaders, useRuntimeConfig} from '#imports';
import type {NuxtApp} from '#app';
import {useCookie} from 'nuxt/app';
import cloneDeep from 'lodash-es/cloneDeep';
import {type $Fetch, $fetch, FetchError, type FetchResponse} from 'ofetch';
import {withBase} from 'ufo';
import {useBackendUrl} from './useBackendUrl';
import {useSpanWrap} from './useSpanWrap';
import {allowedHeaderValues} from '../utils/api-cookie';

export {FetchError as OFetchError} from 'ofetch';

function makeUniversalUrl(url: string, baseURL?: string): string {
    const isAbsolute = /^(?:[a-z+]+:)?\/\//i.test(url);
    if (!isAbsolute && import.meta.server) {
        const host = guestHost();
        // If url aleady starts with baseURL, it will not be added again.
        const urlWithBase = withBase(url, baseURL ?? '');
        return new URL(urlWithBase, host).toString();
    }
    return url;
}

function prepareOptions(nuxtApp: NuxtApp, opts: NonNullable<Parameters<$Fetch>[1]>) {
    const options = cloneDeep(opts);
    const runtimeConfig = useRuntimeConfig();

    let headersToForwardFromClient: Record<string, string | undefined> = {};

    if (import.meta.server) {
        // See https://nuxt.com/docs/getting-started/data-fetching#pass-client-headers-to-the-api

        headersToForwardFromClient = useRequestHeaders();
        headersToForwardFromClient[`x-malt-ssr`] = 'true';

        if (nuxtApp.$apiCookies) {
            const {cookieValues} = nuxtApp.$apiCookies;

            for (const cookieName of allowedHeaderValues) {
                if (cookieValues[cookieName]) {
                    headersToForwardFromClient.cookie = appendToCookieString(headersToForwardFromClient.cookie, cookieName, cookieValues[cookieName]);
                }
            }

            if (cookieValues['XSRF-TOKEN']) {
                if (!['GET', 'HEAD', 'TRACE', 'OPTIONS'].includes(opts.method || '')) {
                    headersToForwardFromClient['x-xsrf-token'] = cookieValues['XSRF-TOKEN'];
                }
            }
        } else {
            console.warn(new Error('The "backend-api-cookies" plugin must be initialized before reaching this point'));
        }

        // SEO bot crawler related behavior fix
        if (headersToForwardFromClient.accept?.includes('text/html')) {
            headersToForwardFromClient.accept = '*/*';
        }
        // Remove Accept-Encoding header as the server fetch client may
        // not support all what supported by the browser like zstd
        delete headersToForwardFromClient['accept-encoding'];
    }

    const csrf = useCookie('XSRF-TOKEN');
    const {appVersion} = runtimeConfig.public;

    options.headers = {
        ...{Accept: 'application/json'},
        ...(options?.headers && options.headers),
        ...(csrf.value && {'x-xsrf-token': csrf.value}),
        ...(appVersion && {'x-app-version': appVersion}),
        ...headersToForwardFromClient,
    };

    // Default timeout on SSR is 5 minutes. We set it to 1 minute by default
    if (import.meta.server) {
        const fetchTimeout = opts.timeout || runtimeConfig.ssrFetchTimeout;
        if (!!fetchTimeout && !isNaN(fetchTimeout as any)) {
            // sorry for "as any" but isNaN should cast string to number if necessary

            if (opts.timeout) {
                options.timeout = +fetchTimeout;
            } else {
                options.timeout = +fetchTimeout * 1000; // From our env var, we use seconds to indicate the timeout
            }
        }
    }

    return options;
}

function appendToCookieString(cookieString: string | undefined, cookieKey: string, cookieValue: string) {
    const cookieParts = (cookieString || '').split(';').filter((part) => !part.trim().startsWith(`${cookieKey}=`));
    const newCookie = `${cookieKey}=${cookieValue}`;
    return [...cookieParts, newCookie].join('; ');
}

function updateServerApiCookies<T>(nuxtApp: NuxtApp, response?: FetchResponse<T>) {
    if (!import.meta.server || !response) {
        return;
    }

    if (nuxtApp.$apiCookies) {
        const {registerServerSideSetCookies} = nuxtApp.$apiCookies;
        registerServerSideSetCookies(response);
    } else {
        console.warn(new Error('The "backend-api-cookies" plugin must be initialized before reaching this point'));
    }
}

export function guestHost() {
    if (import.meta.server) {
        const headers = useRequestHeaders();
        if (!headers.host) {
            return 'https://www.malt.fr';
        }
        return `https://${headers.host}`;
    } else {
        return window.location.origin;
    }
}

/**
 *
 * Create an ofetch instance with a custom baseURL
 *
 * It returns a useUniversalFetch function based on the instance
 *
 * @public-api
 *
 * @param opts - Options from ofetch
 */
export function createUniversalFetch(options: {baseURL: string}): typeof useUniversalFetch {
    const universalFetchRaw = createUniversalFetchRaw(options);

    async function universalFetch<T>(url: string, opts: Parameters<$Fetch>[1] = {method: 'GET'}): Promise<T> {
        const response = await universalFetchRaw(url, opts);
        return response._data as Promise<T>;
    }

    return universalFetch;
}

/**
 *
 * Create an ofetch instance with a custom baseURL
 *
 * It returns a useUniversalFetch function based on the instance
 *
 * @public-api
 *
 * @param opts - Options
 */
export function createUniversalFetchRaw(options: {baseURL: string}): typeof useUniversalFetchRaw {
    const $fetchInstance = $fetch.create(options);
    return createUniversalFetchRawWith$FetchInstance($fetchInstance.raw);
}

function createUniversalFetchRawWith$FetchInstance(fetchClientRaw: typeof $fetch.raw): typeof useUniversalFetchRaw {
    async function universalFetchRaw<T>(url: string, opts: Parameters<$Fetch>[1] = {method: 'GET'}): Promise<FetchResponse<T>> {
        const universalURL = makeUniversalUrl(url, opts.baseURL);
        const nuxtApp = useNuxtApp();
        const fetchOptions = prepareOptions(nuxtApp, opts);

        try {
            if (import.meta.server && process.env.FETCH_DEBUG) {
                const prefix = process.dev ? '\x1B[33m%s\x1B[0m' : '';
                console.log(`${prefix}${universalURL}\n${JSON.stringify(fetchOptions, null, 2)}`, 'useSpanWrap');
            }

            const response = await useSpanWrap(() => fetchClientRaw(universalURL, fetchOptions));

            // WARNING: at this point the Nuxt instance is not accessible anymore because of the "await" above.
            // Do not use any composable after that call!
            // See https://nuxt.com/docs/api/composables/use-nuxt-app#a-deeper-explanation-of-context

            updateServerApiCookies(nuxtApp, response);
            return response;
        } catch (error: unknown) {
            if (error instanceof FetchError) {
                updateServerApiCookies(nuxtApp, error.response);
                if (import.meta.client && typeof window !== 'undefined' && (error.statusCode === 403 || error.statusCode === 401)) {
                    document.dispatchEvent(new Event('user-may-be-disconnected'));
                }
            }
            return Promise.reject(error);
        }
    }

    return universalFetchRaw;
}

/**
 * ## Method : *useUniversalFetchRaw<T>(url: string, opts: Parameters<$Fetch>[1] = {method: 'GET'})*
 *
 * Make universal API calls, returning raw response (headers included).
 *
 * @public-api
 *
 * @param url       A relative or absolute URL passed as string
 * @param opts      Fetch options from ofetch
 */
export async function useUniversalFetchRaw<T>(url: string, opts: Parameters<$Fetch>[1] = {method: 'GET'}): Promise<FetchResponse<T>> {
    const baseURL = useBackendUrl();
    // Using $fetch global instance
    const actualImplementation = createUniversalFetchRawWith$FetchInstance($fetch.raw);
    return await actualImplementation(url, {...opts, baseURL: opts.baseURL ?? baseURL});
}

/**
 * ## Method : *useUniversalFetch<T>(url: string, opts: Parameters<$Fetch>[1] = {method: 'GET'})*
 *
 * Make universal API calls.
 *
 * If the call is made client-side, the composable makes a call to the endpoint defined by the URL, be it relative or
 * absolute.
 *
 * If the call is made server-side, the composable resolve the URL to an absolute one if it's not already the case.
 * Requests headers are used to infer host and protocol in this case.
 *
 * @public-api
 *
 * @param url       A relative or absolute URL passed as string
 * @param opts      Fetch options from ofetch
 */
export async function useUniversalFetch<T>(url: string, opts: Parameters<$Fetch>[1] = {method: 'GET'}): Promise<T> {
    const response = await useUniversalFetchRaw<T>(url, opts);
    return response._data as Promise<T>;
}

export function useUniversalPost<T>(url: string, opts: Parameters<$Fetch>[1] = {}) {
    return useUniversalFetch<T>(url, {...opts, method: 'POST'});
}

export function useUniversalPut<T>(url: string, opts: Parameters<$Fetch>[1] = {}) {
    return useUniversalFetch<T>(url, {...opts, method: 'PUT'});
}

export function useUniversalDelete<T>(url: string, opts: Parameters<$Fetch>[1] = {}) {
    return useUniversalFetch<T>(url, {...opts, method: 'DELETE'});
}
