
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91cityId_93MXCcMuR662Meta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/a/freelance/[familyId]/[categoryId]/[subCategoryId]/[cityId].vue?macro=true";
import { default as _91subCategoryIdOrCityId_9387PIjwrvA7Meta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/a/freelance/[familyId]/[categoryId]/[subCategoryIdOrCityId].vue?macro=true";
import { default as _91categoryIdOrCityId_93n92MpyEB3ZMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/a/freelance/[familyId]/[categoryIdOrCityId].vue?macro=true";
import { default as _91familyIdOrCityId_93bDK79o7mHgMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/a/freelance/[familyIdOrCityId].vue?macro=true";
import { default as cookiesN7zqe3lCr9Meta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/about/privacy/cookies.vue?macro=true";
import { default as indexLd3Rn9g4wwMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/about/privacy/index.vue?macro=true";
import { default as _91id_93le7LRd4XNZMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/about/privacy/policy/[id].vue?macro=true";
import { default as indexlyrAnAjiISMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/about/privacy/policy/index.vue?macro=true";
import { default as rightsKkAegiNhfJMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/about/privacy/rights.vue?macro=true";
import { default as all_45freelances48Sb0uI3cyMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/all-freelances.vue?macro=true";
import { default as adminJvwd0h6Fj7Meta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/barometer/admin.vue?macro=true";
import { default as _91uid_935vGHTPmqZNMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/c/[uid].vue?macro=true";
import { default as error39G3D0qaIdMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/c/comatch/error.vue?macro=true";
import { default as hello_45clientONlSCIGjGWMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/c/comatch/hello-client.vue?macro=true";
import { default as hello_45freelancerDLO2FNUrB2Meta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/c/comatch/hello-freelancer.vue?macro=true";
import { default as hello_45strategylHtEPiAnUiMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/c/comatch/hello-strategy.vue?macro=true";
import { default as helloo8GVaR0EFcMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/c/comatch/hello.vue?macro=true";
import { default as indexJKjmPzkodPMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/c/comatch/index.vue?macro=true";
import { default as _91uid_93PVzPTciLAmMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/c/companies/[uid].vue?macro=true";
import { default as indexwQMop7TEirMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/c/companies/index.vue?macro=true";
import { default as _91uid_93eFOthf646QMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/c/freelancers/[uid].vue?macro=true";
import { default as indexTdyo32hjooMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/c/freelancers/index.vue?macro=true";
import { default as _91uid_933kd2mrYjoYMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/c/maltstrategy/[uid].vue?macro=true";
import { default as index9bRus1coPIMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/c/maltstrategy/index.vue?macro=true";
import { default as _91uid_93HxYv2AeazGMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/c/organization/[uid].vue?macro=true";
import { default as product_45releasejybTbn7vYMMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/c/product-release.vue?macro=true";
import { default as _91uid_938zvDRvMH57Meta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/c/resources/[uid].vue?macro=true";
import { default as index1XNYX2YeKVMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/c/resources/index.vue?macro=true";
import { default as _91uid_93HhUGjrv1ijMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/c/solutions/[uid].vue?macro=true";
import { default as indexYGi85lueJvMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/c/solutions/index.vue?macro=true";
import { default as dashboardOht1tyOM6kMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/cms/admin/dashboard.vue?macro=true";
import { default as indexLZbdbKCXoVMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/cms/admin/index.vue?macro=true";
import { default as prismicY26WmLkxhbMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/cms/admin/prismic.vue?macro=true";
import { default as searcho8fkvwwa55Meta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/cms/admin/search.vue?macro=true";
import { default as tagsvuNkU0qFRHMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/cms/admin/tags.vue?macro=true";
import { default as categoriesekumJXbv2xMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/freelancers/categories.vue?macro=true";
import { default as _91city_93swxyuPR7DdMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/freelancers/locations/[city].vue?macro=true";
import { default as locationsbiCvjLOpOUMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/freelancers/locations.vue?macro=true";
import { default as tagsBvllQEtmsuMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/freelancers/tags.vue?macro=true";
import { default as impressumFkbpkHXnH0Meta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/impressum.vue?macro=true";
import { default as indexAymeyDf0c3Meta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/index.vue?macro=true";
import { default as legal7L59RO51wOMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/legal.vue?macro=true";
import { default as mobile1K2bj35NlIMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/mobile.vue?macro=true";
import { default as _91slug_93jPCRlvelwYMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/s/tags/[slug].vue?macro=true";
import { default as indexcABPfBPrSZMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/t/[urlPrefix]/[familyId]/index.vue?macro=true";
import { default as indexepptSRWdkjMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/t/[urlPrefix]/index.vue?macro=true";
import { default as indexXiHb25VHF3Meta } from "~/pages/t/[urlPrefix]/[familyId]/index.vue?macro=true";
import { default as previewRWt8dgikhZMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/lib-nuxt/nuxt-prismic-module/src/runtime/pages/preview.vue?macro=true";
import { default as redirect5KjWUcJ6qNMeta } from "/tmpfs/builds/maltcommunity/malt/apps/malt/lib-nuxt/nuxt-prismic-module/src/runtime/pages/redirect.vue?macro=true";
export default [
  {
    name: "a-freelance-familyId-categoryId-subCategoryId-cityId",
    path: "/a/freelance/:familyId()/:categoryId()/:subCategoryId()/:cityId()",
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/a/freelance/[familyId]/[categoryId]/[subCategoryId]/[cityId].vue")
  },
  {
    name: "a-freelance-familyId-categoryId-subCategoryIdOrCityId",
    path: "/a/freelance/:familyId()/:categoryId()/:subCategoryIdOrCityId()",
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/a/freelance/[familyId]/[categoryId]/[subCategoryIdOrCityId].vue")
  },
  {
    name: "a-freelance-familyId-categoryIdOrCityId",
    path: "/a/freelance/:familyId()/:categoryIdOrCityId()",
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/a/freelance/[familyId]/[categoryIdOrCityId].vue")
  },
  {
    name: "a-freelance-familyIdOrCityId",
    path: "/a/freelance/:familyIdOrCityId()",
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/a/freelance/[familyIdOrCityId].vue")
  },
  {
    name: "about-privacy-cookies",
    path: "/about/privacy/cookies",
    meta: cookiesN7zqe3lCr9Meta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/about/privacy/cookies.vue")
  },
  {
    name: "about-privacy",
    path: "/about/privacy",
    meta: indexLd3Rn9g4wwMeta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/about/privacy/index.vue")
  },
  {
    name: "about-privacy-policy-id",
    path: "/about/privacy/policy/:id()",
    meta: _91id_93le7LRd4XNZMeta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/about/privacy/policy/[id].vue")
  },
  {
    name: "about-privacy-policy",
    path: "/about/privacy/policy",
    meta: indexlyrAnAjiISMeta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/about/privacy/policy/index.vue")
  },
  {
    name: "about-privacy-rights",
    path: "/about/privacy/rights",
    meta: rightsKkAegiNhfJMeta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/about/privacy/rights.vue")
  },
  {
    name: "all-freelances",
    path: "/all-freelances",
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/all-freelances.vue")
  },
  {
    name: "barometer-admin",
    path: "/barometer/admin",
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/barometer/admin.vue")
  },
  {
    name: "c-uid",
    path: "/c/:uid()",
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/c/[uid].vue")
  },
  {
    name: "c-comatch-error",
    path: "/c/comatch/error",
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/c/comatch/error.vue")
  },
  {
    name: "c-comatch-hello-client",
    path: "/c/comatch/hello-client",
    meta: hello_45clientONlSCIGjGWMeta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/c/comatch/hello-client.vue")
  },
  {
    name: "c-comatch-hello-freelancer",
    path: "/c/comatch/hello-freelancer",
    meta: hello_45freelancerDLO2FNUrB2Meta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/c/comatch/hello-freelancer.vue")
  },
  {
    name: "c-comatch-hello-strategy",
    path: "/c/comatch/hello-strategy",
    meta: hello_45strategylHtEPiAnUiMeta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/c/comatch/hello-strategy.vue")
  },
  {
    name: "c-comatch-hello",
    path: "/c/comatch/hello",
    meta: helloo8GVaR0EFcMeta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/c/comatch/hello.vue")
  },
  {
    name: "c-comatch",
    path: "/c/comatch",
    meta: indexJKjmPzkodPMeta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/c/comatch/index.vue")
  },
  {
    name: "c-companies-uid",
    path: "/c/companies/:uid()",
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/c/companies/[uid].vue")
  },
  {
    name: "c-companies",
    path: "/c/companies",
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/c/companies/index.vue")
  },
  {
    name: "c-freelancers-uid",
    path: "/c/freelancers/:uid()",
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/c/freelancers/[uid].vue")
  },
  {
    name: "c-freelancers",
    path: "/c/freelancers",
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/c/freelancers/index.vue")
  },
  {
    name: "c-maltstrategy-uid",
    path: "/c/maltstrategy/:uid()",
    meta: _91uid_933kd2mrYjoYMeta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/c/maltstrategy/[uid].vue")
  },
  {
    name: "c-maltstrategy",
    path: "/c/maltstrategy",
    meta: index9bRus1coPIMeta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/c/maltstrategy/index.vue")
  },
  {
    name: "c-organization-uid",
    path: "/c/organization/:uid()",
    meta: _91uid_93HxYv2AeazGMeta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/c/organization/[uid].vue")
  },
  {
    name: "c-product-release",
    path: "/c/product-release",
    meta: product_45releasejybTbn7vYMMeta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/c/product-release.vue")
  },
  {
    name: "c-resources-uid",
    path: "/c/resources/:uid()",
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/c/resources/[uid].vue")
  },
  {
    name: "c-resources",
    path: "/c/resources",
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/c/resources/index.vue")
  },
  {
    name: "c-solutions-uid",
    path: "/c/solutions/:uid()",
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/c/solutions/[uid].vue")
  },
  {
    name: "c-solutions",
    path: "/c/solutions",
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/c/solutions/index.vue")
  },
  {
    name: "cms-admin-dashboard",
    path: "/cms/admin/dashboard",
    meta: dashboardOht1tyOM6kMeta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/cms/admin/dashboard.vue")
  },
  {
    name: "cms-admin",
    path: "/cms/admin",
    meta: indexLZbdbKCXoVMeta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/cms/admin/index.vue")
  },
  {
    name: "cms-admin-prismic",
    path: "/cms/admin/prismic",
    meta: prismicY26WmLkxhbMeta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/cms/admin/prismic.vue")
  },
  {
    name: "cms-admin-search",
    path: "/cms/admin/search",
    meta: searcho8fkvwwa55Meta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/cms/admin/search.vue")
  },
  {
    name: "cms-admin-tags",
    path: "/cms/admin/tags",
    meta: tagsvuNkU0qFRHMeta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/cms/admin/tags.vue")
  },
  {
    name: "freelancers-categories",
    path: "/freelancers/categories",
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/freelancers/categories.vue")
  },
  {
    name: "freelancers-locations",
    path: "/freelancers/locations",
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/freelancers/locations.vue"),
    children: [
  {
    name: "freelancers-locations-city",
    path: ":city()",
    meta: _91city_93swxyuPR7DdMeta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/freelancers/locations/[city].vue")
  }
]
  },
  {
    name: "freelancers-tags",
    path: "/freelancers/tags",
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/freelancers/tags.vue")
  },
  {
    name: "impressum",
    path: "/impressum",
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/impressum.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexAymeyDf0c3Meta || {},
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/index.vue")
  },
  {
    name: "legal",
    path: "/legal",
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/legal.vue")
  },
  {
    name: "mobile",
    path: "/mobile",
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/mobile.vue")
  },
  {
    name: "s-tags-slug",
    path: "/s/tags/:slug()",
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/s/tags/[slug].vue")
  },
  {
    name: "t-urlPrefix-familyId",
    path: "/t/:urlPrefix()/:familyId()",
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/t/[urlPrefix]/[familyId]/index.vue")
  },
  {
    name: "t-urlPrefix",
    path: "/t/:urlPrefix()",
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/pages/t/[urlPrefix]/index.vue")
  },
  {
    name: "t-urlPrefix-categoryId",
    path: "/t/:urlPrefix/:familyId/:categoryId",
    component: () => import("~/pages/t/[urlPrefix]/[familyId]/index.vue")
  },
  {
    name: "t-urlPrefix-categoryId-subCategoryId",
    path: "/t/:urlPrefix/:familyId/:categoryId/:subCategoryId",
    component: () => import("~/pages/t/[urlPrefix]/[familyId]/index.vue")
  },
  {
    name: "preview-page",
    path: "/c/preview",
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/lib-nuxt/nuxt-prismic-module/src/runtime/pages/preview.vue")
  },
  {
    name: "redirect_or_404",
    path: "/:pathMatch([^_].*)*",
    component: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/lib-nuxt/nuxt-prismic-module/src/runtime/pages/redirect.vue")
  }
]