import {useRequestHeaders, useRuntimeConfig} from '#imports';

export const useBreakpointsHelper = (): {isWide: boolean; isTablet: boolean; isMobile: boolean} => {
    const {breakpoints} = useRuntimeConfig().public;

    if (import.meta.server) {
        const {'user-agent': userAgent} = useRequestHeaders(['user-agent']);
        const isMobileClient = !!userAgent && /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(userAgent);
        return {
            isWide: false,
            isTablet: isMobileClient,
            isMobile: isMobileClient,
        };
    } else {
        const isMobileWindow = window.innerWidth <= breakpoints.mobile;
        const isTabletWindow = window.innerWidth <= breakpoints.tablet;
        const isWideWindow = window.innerWidth > breakpoints.wide;
        return {
            isWide: isWideWindow,
            isTablet: isTabletWindow,
            isMobile: isMobileWindow,
        };
    }
};
