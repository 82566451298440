import {useCookie, useHead, useRuntimeConfig} from '#imports';
import {PREVIEW_ENABLE_COOKIE} from '../../helpers/constants';

export default function usePrismicPreviewScript() {
    const {prismicRepository} = useRuntimeConfig().public;
    const previewEnabled = useCookie(PREVIEW_ENABLE_COOKIE);

    // @ts-expect-error JSON.parse converts 'true' to true
    if (previewEnabled.value === true || previewEnabled.value === 'true') {
        try {
            useHead({
                script: [
                    {
                        src: `https://static.cdn.prismic.io/prismic.js?repo=${prismicRepository}&new=true`,
                        async: true,
                        defer: true,
                    },
                ],
            });
        } catch (e) {}
    }
}
