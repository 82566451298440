import type {FetchError} from 'ofetch';
import {createError, useLogger, useState, useUniversalFetch} from '#imports';
import type {UserData, UserInfo} from '~/types/custom';

export interface UserDataFetch {
    data?: UserData | null;
}

export function useUserState() {
    const logger = useLogger();

    const user = useState<UserDataFetch>('user', () => ({}));

    const fetchUser = async () => {
        try {
            const userInfo = await useUniversalFetch<UserInfo>('/api/user/me');
            user.value = {
                data: {
                    info: userInfo,
                    isLoggedIn: !!userInfo.username,
                },
            };
        } catch (err: unknown) {
            const error = err as FetchError;
            if (error.statusCode === 403) {
                logger.debug('User is not logged in.');
            } else {
                logger.error(`Error while fetching user data. Error: statusCode=${error.statusCode}, message=${error.message}.`);
            }

            user.value = {
                data: {
                    info: null,
                    isLoggedIn: false,
                },
            };

            throw createError({statusCode: error.statusCode, message: error.message});
        }
    };

    return {
        user,
        fetchUser,
    };
}
