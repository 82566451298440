import type {InjectionKey, Ref} from 'vue';
import type {PrismicDocument} from '@prismicio/types';
import type {
    LandingPageContentWithProfiles,
    LandingPageContentWithHighlightedProfiles,
    GeneralBarometer,
    JobBarometer,
    TagSearchResponse,
} from '~/openapi';
import type {JobPageDocument, JobTemplateDocument, JobPageConfigSlice, TagPageDocument, TagTemplateDocument} from '@prismicio-types';
import type {useHostSettings, PrismicClient, Breakpoints} from '#imports';

export const PRISMIC_CLIENT: InjectionKey<PrismicClient> = Symbol('Prismic Client');
export const PRISMIC_DOCUMENT: InjectionKey<Ref<PrismicDocument | undefined>> = Symbol('Prismic Document');
export const MEDIA_BREAKPOINTS: InjectionKey<Ref<Breakpoints>> = Symbol('Media breakpoints for CSS');
export const HOST_SETTINGS: InjectionKey<ReturnType<typeof useHostSettings> extends Promise<infer R> ? R : never> = Symbol('Current host setting');

export const JOB_PAGE: InjectionKey<{
    landingPage?: LandingPageContentWithProfiles | LandingPageContentWithHighlightedProfiles;
    prismicTemplate?: JobTemplateDocument | undefined;
    prismicPage?: JobPageDocument | undefined;
    jobPageConfig?: JobPageConfigSlice | undefined;
}> = Symbol('Job Page');

export const TAG_PAGE: InjectionKey<{
    landingPage?: TagSearchResponse;
    prismicTemplate?: TagTemplateDocument | undefined;
    prismicPage?: TagPageDocument | undefined;
}> = Symbol('Tag Page');

export const BAROMETER_HOME: InjectionKey<{
    generalBarometer: GeneralBarometer;
    prismicTemplate?: JobTemplateDocument | undefined;
}> = Symbol('Barometer Home');

export const BAROMETER_JOB: InjectionKey<{
    jobBarometer: JobBarometer;
    prismicTemplate?: JobTemplateDocument | undefined;
}> = Symbol('Barometer Job');
