<template>
    <div>
        <NuxtLayout>
            <NuxtPage />
        </NuxtLayout>
    </div>
</template>

<script setup lang="ts">
    import {watchEffect, onBeforeMount, onBeforeUnmount, provide, ref} from 'vue';
    import type {PrismicDocument} from '@prismicio/types';
    import {useHeadMetadata} from '~/composables/useHeadMetadata';
    import {HOST_SETTINGS, MEDIA_BREAKPOINTS, PRISMIC_CLIENT, PRISMIC_DOCUMENT} from '~/app/keys';
    import {useAsyncData} from '#app';
    import {
        navbarUnloggedKeys,
        navigateTo,
        useHead,
        useHostSettings,
        useLocale,
        usePrismicPreviewScript,
        usePrismicClient,
        useScripts,
    } from '#imports';
    import {useBreakpointsHelper} from '~/composables/useBreakpointsHelpers';
    import {useUserState} from '~/composables/useUserState';
    import {useTranslatedUrl} from '~/composables/useTranslatedUrl';
    import {useFeatureFlagsStore} from '~/stores/feature-flags.store';
    import {useABTestStore} from '~/stores/abtest.store';

    const {loadFeatureFlags} = useFeatureFlagsStore();
    await loadFeatureFlags();

    const {fetchCampaignsVariations} = useABTestStore();
    await fetchCampaignsVariations();

    usePrismicPreviewScript();
    const client = usePrismicClient();
    provide(PRISMIC_CLIENT, client);

    const breakpoints = ref(useBreakpointsHelper());
    provide(MEDIA_BREAKPOINTS, breakpoints);

    const prismicDoc = ref<PrismicDocument>();
    provide(PRISMIC_DOCUMENT, prismicDoc);

    const {fetchUser} = useUserState();
    await useAsyncData('user-fetch', () => fetchUser());

    const {data: hostSettings} = await useAsyncData('host-settings', () => useHostSettings());
    provide(HOST_SETTINGS, hostSettings.value!);

    const {locale} = useLocale();

    provide(navbarUnloggedKeys.TRANSLATE_URL_FN, useTranslatedUrl(client, prismicDoc).translateUrl);

    watchEffect(() => {
        if (prismicDoc.value?.type === 'redirect') {
            navigateTo(prismicDoc.value.data.redirectTo, {redirectCode: 301});
        }
        try {
            if (window) {
                window.dispatchEvent(new CustomEvent('Navigation'));
            }
        } catch (err) {}
        useHead(useHeadMetadata(prismicDoc.value, locale.value!));
    });

    const script = useScripts(hostSettings.value);
    useHead({script});

    function handleResize() {
        breakpoints.value = useBreakpointsHelper();
    }

    onBeforeMount(() => {
        window.addEventListener('resize', handleResize);
    });

    onBeforeUnmount(() => {
        window.removeEventListener('resize', handleResize);
    });
</script>

<style lang="scss">
    #__nuxt {
        height: 100vh;
    }
</style>
