import validate from "/tmpfs/builds/maltcommunity/malt/apps/malt/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.4.1_@types+node@20.14.2_db0@0.2.1_encoding@0.1.13_eslint@8.35.0_oj6kj6bfhtz7e2vdxyjb7g7era/node_modules/nuxt/dist/pages/runtime/validate.js";
import redirect_45user_45to_45whatever_45is_45in_45progress from "/tmpfs/builds/maltcommunity/malt/apps/malt/lib-nuxt/nuxt-auth-module/src/runtime/middleware/redirect-user-to-workflow-in-progress.global.ts";
import redirect_45filters from "/tmpfs/builds/maltcommunity/malt/apps/malt/lib-nuxt/nuxt-auth-module/src/runtime/middleware/redirect-filters.global.ts";
import redirect_45user_45to_45profile_45signup_45completion from "/tmpfs/builds/maltcommunity/malt/apps/malt/lib-nuxt/nuxt-auth-module/src/runtime/middleware/redirect-user-to-profile-signup-completion.global.ts";
import manifest_45route_45rule from "/tmpfs/builds/maltcommunity/malt/apps/malt/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.4.1_@types+node@20.14.2_db0@0.2.1_encoding@0.1.13_eslint@8.35.0_oj6kj6bfhtz7e2vdxyjb7g7era/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  redirect_45user_45to_45whatever_45is_45in_45progress,
  redirect_45filters,
  redirect_45user_45to_45profile_45signup_45completion,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "redirect-to-home": () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/middleware/redirectToHome.ts"),
  "redirect-to-hopsearch": () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/middleware/redirectToHopsearch.ts"),
  "redirect-to-landing-page": () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/cms/cms-front/src/middleware/redirectToLandingPage.ts"),
  auth: () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/lib-nuxt/nuxt-auth-module/src/runtime/middleware/auth.ts"),
  "immersion-mode": () => import("/tmpfs/builds/maltcommunity/malt/apps/malt/lib-nuxt/nuxt-auth-module/src/runtime/middleware/immersion-mode.ts")
}