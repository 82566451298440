
import * as prismicRuntime$WDNJwpuQRR from '/tmpfs/builds/maltcommunity/malt/apps/malt/node_modules/.pnpm/@nuxt+image@1.7.0_db0@0.2.1_magicast@0.3.5/node_modules/@nuxt/image/dist/runtime/providers/prismic'
import * as filerobotRuntime$cNggxf8TPI from '/tmpfs/builds/maltcommunity/malt/apps/malt/lib-nuxt/nuxt-image-module/src/runtime/providers/filerobot.ts'
import * as ipxRuntime$stqayMULLS from '/tmpfs/builds/maltcommunity/malt/apps/malt/node_modules/.pnpm/@nuxt+image@1.7.0_db0@0.2.1_magicast@0.3.5/node_modules/@nuxt/image/dist/runtime/providers/ipx'

export const imageOptions = {
  "screens": {
    "xs": 320,
    "sm": 640,
    "md": 768,
    "lg": 1024,
    "xl": 1280,
    "xxl": 1536,
    "2xl": 1536,
    "wide": 1920,
    "desktop": 1536,
    "tablet": 1200,
    "mobile": 991,
    "small": 425,
    "tiny": 360
  },
  "presets": {},
  "provider": "ipx",
  "domains": [],
  "alias": {},
  "densities": [
    1,
    2
  ],
  "format": [
    "webp"
  ]
}

imageOptions.providers = {
  ['prismic']: { provider: prismicRuntime$WDNJwpuQRR, defaults: undefined },
  ['filerobot']: { provider: filerobotRuntime$cNggxf8TPI, defaults: {"baseURL":"https://dam.malt.com"} },
  ['ipx']: { provider: ipxRuntime$stqayMULLS, defaults: {} }
}
        