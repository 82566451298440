import type {KeyTextField, ImageField} from '@prismicio/types';
import {useHosts} from '#imports';

const {getHostsByLocales} = useHosts();

async function getCountryPath(countryKey: string, langKey: string): Promise<string> {
    const hosts = await getHostsByLocales();
    const locale = `${langKey}-${countryKey}`.toLowerCase();
    return hosts[locale] ?? 'www.malt.fr';
}

export function useCountry() {
    return {
        getCountryPath,
    };
}
