import type {Ref} from 'vue';
import type {PrismicDocument} from '@prismicio/types';
import type {PrismicClient} from '#imports';
import {useRoute} from '#imports';
import {useCountry} from './useCountry';

export function useTranslatedUrl(client: PrismicClient, document: Ref<PrismicDocument | undefined>) {
    async function translateUrl(langKey: string, countryKey: string): Promise<string> {
        if (!langKey || !countryKey) {
            return '';
        }

        const {getCountryPath} = useCountry();
        const route = useRoute();

        const alternates = document?.value?.alternate_languages ?? [];
        const translated = alternates.find((alternate) => alternate.lang === `${langKey?.toLowerCase()}-${countryKey?.toLowerCase()}`);
        const host = await getCountryPath(countryKey, langKey);
        const url = `https://${host}`;

        if (!translated) {
            return url;
        }

        const oldUid = typeof route.params.uid === 'string' ? route.params.uid : undefined;
        const newUid =
            oldUid &&
            (translated?.uid || // sometimes uid is not present
                (await client!.getByID(translated.id, {lang: translated.lang}).then((doc: PrismicDocument) => doc?.uid))); // no need to translate if route has no uid

        const translatedPath = oldUid && newUid ? route.path.replace(oldUid, newUid) : route.path;

        return `${url}${translatedPath}`;
    }

    return {
        translateUrl,
    };
}
